body {
  background-color: #000000; /* primary.main */
}

/* 
@media (min-width: 600px) {
  body {
    background-color: #24323d;
  }
}


@media (min-width: 960px) {
  body {
    background-color: #24323d; 
  }
}

@media (min-width: 1280px) {
  body {
    background-color: #f4f5f7; 
  }
}

@media (min-width: 1920px) {
  body {
    background-color: #f4f5f7; 
  }
}
*/
